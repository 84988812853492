<template>
<v-container fluid>
  <v-row class="d-flex align-start">
    <v-col cols="9">
      <v-container fluid>
        <div v-if="!!pickedDate" class="text-h6">下属日报 {{ pickedDate.year }}年{{pickedDate.month + 1 }}月{{ pickedDate.date }}日（星期{{ weekDays[pickedDate.dayOfWeek] }})</div>
        <div class="my-2">
          <v-btn @click="openNotificationDlg" color="primary" small>提醒大家写日报</v-btn>
        </div>        
        <v-divider class="mt-3"></v-divider>
        <v-row style="height:300px;overflow:auto;">
          <v-container fluid>
            <!-- missed sub users -->
            <v-row v-if="missedSubUsers.length > 0">
              <v-col class="justify-center align-center d-flex" cols="2">
                <div>
                  <div class="red--text pl-4">{{ missedSubUsers.length }}</div>
                  <div class="red--text">未提交</div>
                </div>
              </v-col>
              <v-col>
                <div v-for="(user, i) in missedSubUsers" :key="i">
                  <v-divider v-if="i!==0"></v-divider>
                  <div class="d-flex justify-start align-center ma-2">
                    <UserAvatar class="ma-1" :name="user.employeeName.charAt(0)" width='30px' height='30px' :userId="user.id" />
                    <div style="width:100px;">{{ user.employeeName }}</div>
                    <v-spacer></v-spacer>
                    <div style="cursor:pointer;" @click="openNotificationDlg" class="blue--text">提醒ta写周报</div>
                    <v-spacer></v-spacer>
                  </div>
                  <v-divider v-if="i!==missedSubUsers.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- checked sub users -->
            <v-row v-if="checkedSubUsers.length > 0">
              <v-col class="justify-center align-center d-flex" cols="2">
                <div>
                  <div class="green--text pl-4">{{ checkedSubUsers.length }}</div>
                  <div class="green--text">按时提交</div>
                </div>
              </v-col>
              <v-col>
                <div v-for="(user, i) in checkedSubUsers" :key="i">
                  <v-divider v-if="i!==0"></v-divider>
                  <div class="d-flex justify-start align-center ma-2">
                    <UserAvatar class="ma-1" :name="user.employeeName.charAt(0)" width='30px' height='30px' :userId="user.id" />
                    <div style="width:100px;">{{ user.employeeName }}</div>
                    <v-spacer></v-spacer>
                    <!-- <div class="blue--text">提醒ta写周报</div> -->
                    <!-- <v-spacer></v-spacer> -->
                  </div>
                  <v-divider v-if="i!==checkedSubUsers.length-1"></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-divider></v-divider>
      </v-container>
    </v-col>
    <v-spacer></v-spacer>
    <v-col>
      <date-calendar @pick="pickDate" :expireds="expireds" :actives="actives" :current="current"></date-calendar>
    </v-col>
  </v-row>
  <!-- reports -->
  <v-row>
    <TabFirstItem :active="actives" v-for="(rp, index) in todayReports" :key="index" :report="rp" type="daily" @displayRightSummaryEdit="openRightSummaryEditor" />
  </v-row>
  <sub-notification-dlg @agree="sendNotification" ref="subNotificationDlg"></sub-notification-dlg>
</v-container>
</template>

<script>
import moment from 'moment';
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  name: 'SubDailyReports',
  components: {
    DateCalendar: () => import('@/components/summary/DateCalendar.vue'),
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    TabFirstItem: () => import('@/components/summary/TabFirstItem.vue'),
    SubNotificationDlg: () => import('@/components/summary/SubNotificationDlg.vue')
  },
  data: () => ({
    current: null,
    expireds: [],
    pickedDate: null,
    weekDays: ['日', '一', '二', '三', '四', '五', '六'],
    checkedSubUsers: [],
  }),
  computed: {
    ...mapGetters('user', ['subUsers']),
    ...mapGetters('auth', ['authUser']),
    ...mapGetters('report', ['allDailyReport']),
    todayReports: function () {
      this.checkedSubUsers = [];
      if (!this.pickedDate) return;
      return this.allDailyReport.filter(report => {
        const rpDate = moment(new Date(report.rp_date)).format('YYYY-MM-DD');
        const pDate = moment(new Date(this.pickedDate.year, this.pickedDate.month, this.pickedDate.date)).format('YYYY-MM-DD');
        if (rpDate == pDate) {
          const index = this.subUsers.findIndex(user => user.id == report.rp_creator);
          if( index !== -1) {
            this.checkedSubUsers.push(this.subUsers[index]);
            return report;
          }          
        }
      })
    },
    missedSubUsers: function () {
      return this.subUsers.filter(user => {
        const index = this.checkedSubUsers.findIndex(cu => cu.id == user.id);
        if(index == -1) {
          return user;
        }
      })
    },
    actives: function () {
      let temp = [];
      if(!this.pickedDate) return;
      const start = new Date(this.pickedDate.year, this.pickedDate.month, 1).getTime();
      const end = new Date(this.pickedDate.year, this.pickedDate.month + 1, 0).getTime();
      this.allDailyReport.map(report => {
        const rpDate = new Date(report.rp_date).getTime();
        if(rpDate > start && rpDate < end) {
          const index = this.subUsers.findIndex(user => user.id == report.rp_creator);
          if(index !== -1) {
            temp.push(report.rp_date);
          }          
        }
      })
      return temp;
    }
  },
  methods: {
    ...mapActions('user', ['getSubUsers']),
    ...mapActions('report', ['getAllDailyReportWithCreator']),
    pickDate(e) {
      this.pickedDate = Object.assign({}, e);
    },
    openRightSummaryEditor(prop) {
      let param = {
        value: prop,
        type: "daily",
      }
      this.$emit("displayRightSummaryEdit", param);
    },
    openNotificationDlg() {
      this.$refs.subNotificationDlg.open({users: this.missedSubUsers, type: 'daily'});
    },
    sendNotification(e) {

    }
  },
  mounted() {
    this.getSubUsers({
      user_superior: this.authUser.id
    });
    const today = new Date();
    this.pickedDate = {
      year: today.getFullYear(),
      month: today.getMonth(),
      date: today.getDate(),
      dayOfWeek: today.getDay()
    };
    this.current = {
      year: today.getFullYear(),
      month: today.getMonth(),
      date: today.getDate(),
      dayOfWeek: today.getDay()
    };
    this.getAllDailyReportWithCreator({
      "rp_type": 0
    });
  }
}
</script>
